.radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
  color: #80868b;
  background-color: #fff;
  border-color: #d1d5db;
  border-width: 1px;
}
.radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.radio:focus {
  outline: none;
  --ring-offset-shadow: 0 0 0 var(--ring-offset-width, 2px)
    var(--ring-offset-color, #fff);
  --ring-shadow: 0 0 0 calc(2px + var(--ring-offset-width, 2px))
    var(--ring-color, #36393f);
  box-shadow: var(--ring-offset-shadow), var(--ring-shadow), 0 0 #0000;
  box-shadow: var(--ring-offset-shadow), var(--ring-shadow),
    var(--box-shadow, 0 0 #0000);
}
.radio:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}
